
import addressesForPlotNumbers from "~/graphql/Property/AddressesForPlotNumbers.gql";
import CompanyLink from "~/components/EntityLink/components/CompanyLink.vue";
import { sortByAddress } from "~/helpers/sort-helpers.js";
import { createTenantsSheet, writeSheetsToXSLXAndSave } from "~/helpers/download-helpers.js";

export default {
  apollo: {
    addressesForPlotNumbers: {
      query: addressesForPlotNumbers,
      variables() {
        return {
          input: this.plotNumbers,
        };
      },
    },
  },

  components: {
    CompanyLink,
  },

  props: {
    plotNumbers: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      searchTerm: "",
      sortFunciton: "by_companyname",
      isDesc: false,
      headers: [
        { name: this.$t("TENANT_LABEL_COMP"), active: true, sortFnc: "by_companyname" },
        { name: this.$t("TENANT_LABEL_INDUSTRY"), active: false, sortFnc: "by_branch" },
        { name: this.$t("TENANT_LABEL_EST"), active: false, sortFnc: "by_establishment" },
        { name: this.$t("TENANT_LABEL_EQUITY"), active: false, sortFnc: "by_equity" },
        { name: this.$t("TENANT_LABEL_ADDRESS"), active: false, sortFnc: "sortByAddress" },
      ],
      daSearch: new Intl.Collator("da", {
        numeric: true,
        sensitivity: "base",
      }),
    };
  },

  computed: {
    loading() {
      return this.$apollo.queries.addressesForPlotNumbers.loading;
    },

    noResults() {
      return this.addressesForPlotNumbers.every((x) => x.tenants.length == 0);
    },

    sortedFilteredTenants() {
      const result = this.addressesForPlotNumbers
        ?.filter((x) => x?.tenants?.length)
        ?.flatMap((x) => x?.tenants)
        ?.sort(this[this.sortFunciton])
        ?.filter(
          (x) =>
            x.company?.name?.match(new RegExp(this.searchTerm, "i")) || this.$options.filters.addressLong(x.address).match(new RegExp(this.searchTerm, "i"))
        );

      if (this.isDesc) {
        return result.reverse();
      }
      return result;
    },
  },

  methods: {
    goToKeyFigures(company) {
      this.$router.push({
        name: "explore-company-id-key-figures",
        params: { id: company.cvr },
      });
    },

    selectSorting(item) {
      if (item.active) {
        this.isDesc = !this.isDesc;
        return;
      }
      this.headers.forEach((itm) => (itm.active = false));
      item.active = true;
      this.isDesc = false;
      this.sortFunciton = item.sortFnc;
    },

    downloadTenants() {
      let data = this.convertOwners();
      if (!data) return;

      const tentantsSheet = createTenantsSheet(data, this.$i18n.locale);
      writeSheetsToXSLXAndSave([tentantsSheet], "Download");
    },

    convertOwners() {
      return this.addressesForPlotNumbers
        ?.filter((x) => x?.tenants?.length)
        ?.flatMap((x) => x?.tenants)
        ?.map((t) => {
          return {
            companyName: t.company?.name,
            industry: t.company?.mainBranchText,
            established: t.company?.establishedAt,
            equity: t.company?.keyFigures?.[0]?.equity,
            address: this.$options.filters.addressLong(t.address),
          };
        });
    },

    by_companyname: function (a, b) {
      return this.daSearch.compare(a.company.name, b.company.name);
    },

    by_establishment: function (a, b) {
      return this.$moment.utc(a.company.establishedAt).diff(this.$moment.utc(b.company.establishedAt));
    },

    by_branch: function (a, b) {
      return this.daSearch.compare(a.company.mainBranchText, b.company.mainBranchText);
    },

    by_equity: function (a, b) {
      // luppilau: MIN_SAFE_INTEGER is used to have missing values be "less" than negative values when sorting
      const ak = (a.company?.keyFigures && a.company?.keyFigures[0].equity) || Number.MIN_SAFE_INTEGER;
      const bk = (b.company?.keyFigures && b.company?.keyFigures[0].equity) || Number.MIN_SAFE_INTEGER;
      return parseInt(ak, 10) - parseInt(bk, 10);
    },

    sortByAddress,
  },
};
