
import PropertyTenants from "../components/mainproperty/PropertyTenants.vue";
import CondoTenants from "../components/condo/CondoTenants.vue";
import { getPropertyHeaderQuery } from "~/helpers/apollo/apollo-property-query-helpers.js";

export default {
  name: "Tenants",

  apollo: {
    propertyByBFENumber: getPropertyHeaderQuery,
  },

  title() {
    return `${this.$t("TENANT_TAB")}`;
  },

  components: {
    PropertyTenants,
    CondoTenants,
  },

  computed: {
    loading: function () {
      return this.propertyByBFENumber == null || this.$apollo.queries.propertyByBFENumber.loading;
    },
    isProperty: function () {
      return this.propertyByBFENumber?.plots != null;
    },
    isCondo: function () {
      return this.propertyByBFENumber?.condo != null;
    },
    propertyPlotNumbers: function () {
      return this.propertyByBFENumber.plots?.filter((plot) => plot != null)?.map((x) => x.plotNumber) || [];
    },
    condoTenants: function () {
      return this.propertyByBFENumber.condo?.unit?.address?.tenants || [];
    },
  },
};
